import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import EventList from '../components/event-list'
import SEO from '../components/seo'

export const TagTemplate = ({ tag, events }) => {
  return (
    <>
      <SEO
        title={`Movies and events tagged '${tag}' at The Projector Cinema`}
        description={`All the best movies and and exciting events tagged '${tag}' at the projector cinema `}
      />
      <div className="container flex flex-col items-center">
        <h1>
          Films and events tagged <i>'{tag}'</i>
        </h1>
        <EventList events={events} />
      </div>
    </>
  )
}

const TagPage = ({ data, pageContext }) => {
  const events = data.events.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
  }))

  return <TagTemplate tag={pageContext.tag} events={events} />
}

export default TagPage

TagPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query EventByTag($tag: String!) {
    events: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "films-and-events" } }
        frontmatter: { tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            veeziFilmId
            eventTypes
            title
            coverImage {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 232, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            blurbHtml
            rating
            releasingSchedules
          }
        }
      }
    }
  }
`
